import { Route, Routes} from "react-router-dom"
import Home from "./Home"
import Projects from "./Projects"
import About from "./About"
import Resume from "./Resume"
import Contact from "./Contact"

export default function Main(){
    return(
        <div>
            <Routes>
                <Route path='/' element={<Home />}/>
                {/* <Route path='/about' element={<About />}/>
                <Route path='/resume' element={<Resume />}/>
                <Route path='/projects' element={<Projects />}/>
                <Route path='/contact' element={<Contact />}/> */}
            </Routes>
        </div>
    )
}