import { Link } from 'react-router-dom'

export default function Nav(){


    return(
            <div className='nav-bar'>
                {/* <Link to="/">
                    <h3>Home</h3>
                </Link>                 
                <Link to="/about">
                    <h3>About</h3>
                </Link> 
                <Link to="/projects">
                    <h3>Projects</h3>
                </Link>         
                <Link to="/resume">
                    <h3>Resume</h3>
                </Link> 
                <Link to="/contact">
                    <h3>Contact</h3>
                </Link>  */}
            </div>
    )
}