import './styles/style.css'
import Nav from './components/Nav';
import Main from './components/Main'
import Footer from './components/Footer'
function App() {
  return (
    <div className="App">
      <header className="App-header">
     <Nav/>
     <Main />
     <Footer />
      </header>
    </div>
  );
}

export default App;
