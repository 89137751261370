export default function Footer(){
    return(
        <div></div>
        // <div className="footer-container">
        //     <div className="footer-nav">
        //         <a target="_blank" href="http://linkedin.com/in/elliottaventura"><img src="https://cdn-icons-png.flaticon.com/512/174/174857.png"/></a>
        //         {/* <a target="_blank" href="https://instagram.com/elliottventura"><img src="https://www.edigitalagency.com.au/wp-content/uploads/new-Instagram-logo-png-full-colour-glyph-1200x1199.png"/></a> */}
        //         <a target="_blank" href="https://github.com/eventura26"><img src="https://cdn-icons-png.flaticon.com/512/25/25231.png"/></a>
        //         <a href="mailto:elliott.ventura26@gmail.com">elliott.ventura26@gmail.com</a>
        //     </div>
        //     <div className="logos">
        //         <img src="https://www.vectorlogo.zone/logos/w3_html5/w3_html5-icon.svg"/>
        //         <img src="https://www.vectorlogo.zone/logos/w3_css/w3_css-icon.svg"/>
        //         <img src="https://www.vectorlogo.zone/logos/sass-lang/sass-lang-icon.svg"/>
        //         <img src="https://www.vectorlogo.zone/logos/javascript/javascript-icon.svg"/>
        //         <img src="https://www.vectorlogo.zone/logos/python/python-icon.svg"/>
        //         <img src="https://www.vectorlogo.zone/logos/reactjs/reactjs-icon.svg"/>
        //         <img src="https://www.vectorlogo.zone/logos/nodejs/nodejs-horizontal.svg"/>
        //         <img src="https://www.vectorlogo.zone/logos/sequelizejs/sequelizejs-icon.svg"/>
        //         <img src="https://www.vectorlogo.zone/logos/djangoproject/djangoproject-ar21.svg"/>
        //         <img src="https://www.vectorlogo.zone/logos/postgresql/postgresql-icon.svg"/>
        //     </div>
        // </div>
    )
}