import { Link } from "react-router-dom";
import resetti from "./media/resetti.png";
import bubble from "./media/dialog.svg"

export default function Home() {
  return (
    <div className="container">
      <div className="image-container">
        <img src={resetti} alt="Resetti" />
        <img src={bubble} alt="Dialog bubble" className="dialog-bubble" />
      </div>
      <div className="links">
        <a target="_blank" href="http://linkedin.com/in/elliottaventura">
          <img style={{ width: '50px', height: '50px' }} src="https://cdn-icons-png.flaticon.com/512/174/174857.png" alt="LinkedIn" />
        </a>
        <a target="_blank" href="https://github.com/eventura26">
          <img style={{ width: '50px', height: '50px' }} src="https://cdn-icons-png.flaticon.com/512/25/25231.png" alt="GitHub" />
        </a>
        <a href="mailto:elliott.ventura26@gmail.com">elliott.ventura26@gmail.com</a>
      </div>
    </div>
  


    //     <div className="main-container">
    //         <div className="home-container">
    //             <div className="welcome-container">
    //                 <div className="welcome">
    //                     <h2>Howdy! My name is</h2>
    //                     <h1>Elliott Ventura</h1>
    //                     <h3>I'm a Software Engineer based out of Houston, TX</h3>
    //                 </div>
    //                 <div className="mini-nav">
    //                     <div className="nav-links">
    //                         <Link to="/about">
    //                             <h3>- About Me</h3>
    //                         </Link>
    //                         <Link to="/projects">
    //                         <h3>- Projects</h3>
    //                         </Link>
    //                         <Link to="/resume">
    //                         <h3>- Resume</h3>
    //                         </Link>
    //                         <Link to="/contact">
    //                         <h3>- Contact Me</h3>
    //                         </Link>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //         <div>
    //             <img className="headshot"  alt="headshot" src="https://i.imgur.com/V5MbamC.jpg" />
    //         </div>
    //     </div>
  );
}
